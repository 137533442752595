<template>
    <div  class="row">
        <div>
            <draggable
                v-model="list3"
                v-bind="dragOptions"
               
                @change = "updateItems"
                group="people"
            >
                <transition-group type="transition">
                    <div class="sort-item px-2 py-1" v-for="field in list3" :key="field">
                        <v-icon class="pr-2" small>mdi-arrow-expand-all</v-icon> 
                        {{ field.name }}
                    </div>
                </transition-group>
            </draggable>

             <div class="ma-2 mt-6">
                <h3>list 3</h3>
                <div class="" v-for="(field, index) in list3" :key="index">
                    {{index+1}}) {{field.name}}<br>
                </div>
            </div>
        </div>
        <div>
            <draggable
                v-model="list2"
                v-bind="dragOptions"
                
                @change = "updateItems"
                group="people"
            >
                <transition-group type="transition">
                    <div class="sort-item px-2 py-1" v-for="field in list2" :key="field">
                        <v-icon  class="pr-2" small>mdi-arrow-expand-all</v-icon> <!-- mdi-arrow-all mdi-arrow-expand-all mdi-timeline-remove-outline -->
                        {{ field.name }}
                    </div>
                </transition-group>
            </draggable>
            <div class="ma-2 mt-6">
                <h3>list 2</h3>
                <div class="" v-for="(field, index) in list2" :key="index">
                    {{index+1}}) {{field.name}}<br>
                </div>
            </div>
        </div>   
  </div>
</template>

<script>
import draggable from "vuedraggable";

let aTest= ["Wim", "Wimpie", "Willem", "Willempie"];

export default {
  //name: "transition-example-2",

  components: {
    draggable
  },
  data() {
    return {
        list1: [
            { name: "Chris", order: 0 },
            { name: "Jan",   order: 1 },
            { name: "Klaas", order: 2 }
        ],
        list2: [
            { name: "Christien", order: 3 },
            { name: "Jantien",   order: 4 },
            { name: "Klazien", order: 5 }
        ],
        
        list3: aTest.map((name, index) => {
            return { name, order: index + 1 };
        }),
      
        drag: false
    };
  },
  methods: {
    // sort() {
    //   this.list = this.list.sort((a, b) => a.order - b.order);
    // }
  },
  computed: {
    dragOptions() {
      return {
        animation: 500,       
        //group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style>
    .sort-item {
        width:200px;
        cursor: move;
        background-color:#EEEEFF;
        border:1px solid gray;
        border-radius:5px;
        margin:0 4px
    }

    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }

    /* .flip-list-move {
    transition: transform 0.5s;
    }

    .no-move {
    transition: transform 0s;
    } */

</style>